import React from "react"
import { Section, SectionTitle, SectionDescription, MainPanel, LeftPannel, RightPannel, MarkdownContent } from "../components/section"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductRoofHero from "../sections/Product/ProductRoofHero"
import ProductRoofStyle from "../sections/Product/ProductRoofStyle"
import CategoryLandingProducts from "../sections/Product/CategoryLandingProducts"
import ProductForm from "../sections/Product/ProductForm"
import generateHTML from '../utils/generateHTML';
import Content, { HTMLContent } from '../components/Content';


const ProductCategoryLanding = ({ data, location }) => {
  const pageData = data.contentfulProductCategoryLanding
  const PostContent = HTMLContent || Content;
  const productList = data.allContentfulProduct
  const productsData = []
  productList.edges.forEach(function(item){
    if (item.node.productCategory.name === pageData.category.name) {
      productsData.push(item)
    }
  })
  return(
    <Layout location={location}>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription}/>
      <ProductRoofHero title={pageData.heroTitle} description={pageData.heroDescription} roofs={pageData.supportedRoof} location={location} />
      <CategoryLandingProducts
        title={pageData.productListSectionTitle}
        description={pageData.productListSectionDescription}
        productsData={productsData}
        category={pageData.category.name}
        location={location}
      />
      <Section pt="70px" pb="35px" bg="#F6F6F6" bdrBottom="#ddd">   
        <div className="container">
          <SectionTitle maxWidth="550px">{pageData.lastSectionTitle}</SectionTitle>
          <SectionDescription>{pageData.lastSectionDescription.lastSectionDescription}</SectionDescription>               
        </div>
        <ProductRoofStyle data={pageData.supportedRoof} />
      </Section>
      <Section pt="90px" pb="90px" bg="#F6F6F6" mt="30px">
        <div className="container">
          <MainPanel>
            <LeftPannel>
              <MarkdownContent>
                <PostContent
                  content={generateHTML(pageData.content.childMarkdownRemark.html)}
                />
              </MarkdownContent>
            </LeftPannel>
            <RightPannel>
              <ProductForm location={location} />
            </RightPannel>
          </MainPanel>
        </div>
      </Section>
    </Layout>
  )
} 

export default ProductCategoryLanding

export const pageQuery = graphql`
  query ProductCategoryLandingQuery($id: String!) {
    contentfulProductCategoryLanding(id: { eq: $id }) {
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      category {
        name
      }
      supportedRoof {
        heading
        description{
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid(maxHeight: 400) {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        linkText
        link
      }
      productListSectionTitle
      productListSectionDescription
      lastSectionTitle
      lastSectionDescription {
        lastSectionDescription
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulProduct(
      sort: {fields: totalPrice}
    ) {
      edges {
        node {
          id
          productName
          image {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          width
          height
          length
          totalPrice
          depositPrice
          popularLabel
          priceDescription
          featuresImage {
            title
            file {
              url
            }
          }
          productRoof {
            roofName
          }
          popularLabel
          url
          productCategory {
            name
            link
          }

        }
      }
    }
  }
`